import { EditableList } from "../../editableList";
import { ObjectMethodForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer, renderItemListLabel } from "../../common";

export function MethodList({ baseProtocol, items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectMethodForm>) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const baseItems = baseProtocol.flat.methods
    const listItems : ApiListItem<ObjectMethodForm>[] = Object.entries(items)
    .filter(([_,form])=>!baseItems[form.spec.id])
    .map(([id,form]) => ({ 
        id, 
        selectable: form.editable,
        removeable: form.editable,
        form, 
        callable: !form.spec.systemHook, 
        name: renderItemListLabel(form.spec),
        onCall: () => {}
    })).toSorted((a,b) => a.name.localeCompare(b.name))


    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId||undefined}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>
        {children}
    </EditableList>

}