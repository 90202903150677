import { EditableList } from "../../editableList";
import { ObjectOutletForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer, renderItemListLabel } from "../../common";


/**
 * for virtual objects, some outlets may be readOnly (static), other editable
 * for script objects, rather all are editable?
 * @param props
 * @returns
 */
export function OutletList({baseProtocol, items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectOutletForm> ) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const baseItems = baseProtocol.flat.outlets
    const listItems : ApiListItem<ObjectOutletForm>[] = Object.entries(items)
    .filter(([_,form])=>!baseItems[form.spec.id])
    .map(([id,form]) => ({ 
        id, 
        selectable: form.editable || form.config.editable, 
        removeable: form.editable,
        name: renderItemListLabel(form.spec),
        form
    })).toSorted((a,b) => a.name.localeCompare(b.name))


    return (<EditableList
        shrunkWidth={250}
        selectedItem={editedItemId||undefined}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>

        {children}

    </EditableList>

    )
}