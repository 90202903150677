import { Stack } from "@mui/material"
import { useState, useEffect, useMemo } from "react"
import { ActionApiModel } from "../model"
import { ObjectApi } from "@grenton/gm-common"
import { CallableApiItem } from "./types"
import { CallableItemList } from "./CallableItemList"
import { CallableParamsForm } from "./CallableParamsForm"


function callableItems(api:ObjectApi) : CallableApiItem[] {
    const items:CallableApiItem[] = [
        ...Object.values(api.flat.features).filter(m => !m.readOnly).map(item => ({ type: 'feature' as const, id:item.id, name: item.label??item.id, params: [{ id: 'value', unit: item.unit, type: item.type }]})),
        ...Object.values(api.flat.methods).filter(m => !m.systemHook).map(item => ({ type: 'method' as const, id:item.id, name: item.label??item.id, params: item.params??[] }))
    ]
    return items
}


export function ActionApiWidget(props: {
    api: ObjectApi,
    model: ActionApiModel,
    onUpdate: (model: ActionApiModel) => void
}) {

    const { model, api, onUpdate } = props
    const items = useMemo(()=>callableItems(api), [api])
    const [selected, setSelected] = useState<string>("")
    const selectedItem = useMemo(()=>items.find(item => item.id === selected), [selected, items])

    useEffect(() => {
        setSelected(items.find(m => Boolean(model.callables[m.id]?.enabled))?.id || '')
    }, [api])

    return <Stack height={200} direction="row">
        
        <CallableItemList 
            items={items} 
            model={model} 
            selectedCallable={selected} 
            onSelectedCallable={setSelected} 
            onToggleCallable={(id, enabled) => {
                const callables = { ...model.callables, [id]: { ...model.callables[id], enabled } }
                onUpdate({ ...model, callables })
            }}/>

        {selectedItem && <CallableParamsForm 
            disabled={!Boolean(model.callables[selected]?.enabled)}
            item={selectedItem}
            values={model.callables[selected]?.params || {}}
            onValueChange={(id, value) => {
                const callables = { ...model.callables, [selected]: { ...model.callables[selected], params:{...model.callables[selected]?.params, [id]:value} } }
                onUpdate({...model, callables})
            }}
            />}



    </Stack>
}
