import * as apiUtils from './api';
import { ObjectApi, ObjectOutlet } from './model';
import { ObjectApiImpl, ApiItemsImpl } from './object-api-impl';

export type OutletApiPlaceholder = {
    requireApi: string;
    outlet: ObjectOutlet;
};

const PLACEHOLDER_API: ObjectApi = new ObjectApiImpl('placeholder', {}, ApiItemsImpl.EMPTY, []);

function nameMap<T extends { id: string }>(all: Record<string, T>, val: T): Record<string, T> {
    return { ...all, [val.id]: val };
}

type CreateApiResult =
    | {
          // no problems at all
          status: 'resolved';
          api: ObjectApiImpl;
      }
    | {
          // missing extended APIs
          status: 'unresolved-extending';
          missingRefs: string[];
      }
    | {
          // missing outlets APIs
          status: 'unresolved-outlets';
          api: ObjectApiImpl;
          unresolved: OutletApiPlaceholder[];
      };

export function createApiForSpec(
    namedSpec: apiUtils.NamedApiSpec,
    baseProtocol: apiUtils.ProtocolID,
    resolver?: (apiId: string) => ObjectApi | null,
): CreateApiResult {
    const extending_api_id: string[] = [];

    if (namedSpec.spec.extending?.length) {
        extending_api_id.push(...namedSpec.spec.extending);
    }

    if (namedSpec.id !== baseProtocol && !extending_api_id.includes(baseProtocol)) {
        extending_api_id.push(baseProtocol);
    }

    const extending: (ObjectApi | string)[] = extending_api_id.map((apiID) => {
        return (resolver ? resolver(apiID) : null) || apiID;
    });

    const missingRefs: string[] = extending.filter((a) => typeof a === 'string') as string[];
    if (missingRefs.length) {
        return {
            status: 'unresolved-extending',
            missingRefs,
        };
    }

    const outlets: Record<string, ObjectOutlet> = {};
    const unresolved: OutletApiPlaceholder[] = [];

    namedSpec.spec.outlets?.forEach((o) => {
        if (outlets[o.id]) {
            throw new Error(`api "${namedSpec.id}" has a duplicated outlet ${o.id}`);
        }

        const apiRef = o.protocol;
        if (!apiUtils.isApiRef(apiRef)) {
            throw new Error(`outlet "${o.id}" must use API reference. Inline spec is not supported`);
        }
        const childApi: ObjectApi = (resolver ? resolver(apiRef.ref) : null) || PLACEHOLDER_API;

        const outlet: ObjectOutlet = {
            id: o.id,
            label: o.label,
            bidirectional: o.bidirectional,
            readOnly: o.bidirectional !== undefined ? true : o.readOnly,
            maxItems: o.bidirectional ?? o.maxItems,
            api: childApi,
        };

        outlets[o.id] = outlet;

        if (childApi === PLACEHOLDER_API) {
            unresolved.push({ requireApi: apiRef.ref, outlet });
        }
    });

    const api = new ObjectApiImpl(
        namedSpec.id,
        namedSpec.spec.meta ?? {},
        new ApiItemsImpl(
            namedSpec.spec.events?.reduce(nameMap, {}) ?? {},
            namedSpec.spec.methods?.reduce(nameMap, {}) ?? {},
            namedSpec.spec.features?.reduce(nameMap, {}) ?? {},
            outlets,
        ),
        extending.filter((a) => typeof a !== 'string') as ObjectApiImpl[],
    );

    return unresolved.length ? { status: 'unresolved-outlets', api, unresolved } : { status: 'resolved', api };
}
