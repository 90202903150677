import { type ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Stack, FormControlLabel, Checkbox, MenuItem, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import type { AddCategoryInputs, AddCategoryProps } from "./types";
import { colors } from './consts';
import { EditTagCategoryCommand } from "@grenton/gm-logic";
import { RemoveTagCategoryCommand } from "@grenton/gm-logic";
import GMTextField from "../../../../../ui/controls/GMTextField";
import { ModalFullScreenMobile, TagLabel } from "@grenton/design-system";
import { useDispatcher, useProject } from "@grenton/gm/ui";
import { LOC_CATEGORY } from "@grenton/gm-common/src";

export function ModalCategory({ isOpen, onClose, category }: AddCategoryProps): ReactElement {
    const theme = useTheme();
    const isLoc = category?.name === LOC_CATEGORY;
    const project = useProject();
    const dispatcher = useDispatcher();
    const tags = project.tags;
    const categoryData = useMemo(() => tags?.getCategory(category?.name || ""), [tags, category?.name]);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

    const { register, watch, handleSubmit, reset, setValue, formState: { errors } } = useForm<AddCategoryInputs>({
        defaultValues: {
            name: categoryData?.data?.name || "",
            color: categoryData?.data?.color || '',
            multiple: categoryData?.data?.multiple || false,
        }
    });

    useEffect(() => {
        if (categoryData) {
            setValue('name', categoryData.data?.name || "");
            setValue('color', categoryData.data?.color || "");
            setValue('multiple', categoryData.data?.multiple || false);
        }
    }, [categoryData, setValue]);

    const colorsItems = useMemo(() => colors.map(c => (
        <MenuItem key={c} value={c}>
            <TagLabel label={c} color={c}/>
        </MenuItem>
    )), []);

    const closeAndReset = useCallback(() => {
        setIsConfirmDeleteOpen(false);
        reset();
        onClose();
    }, [onClose, reset]);

    const handleDelete = useCallback(() => {
        if (isConfirmDeleteOpen) {
            if (categoryData?.name) {
                dispatcher(new RemoveTagCategoryCommand({form: {name: categoryData?.name}}))
            }
            closeAndReset();
        } else {
            setIsConfirmDeleteOpen(true);
        }
    }, [isConfirmDeleteOpen, categoryData]);

    const onSubmit = useCallback((data: AddCategoryInputs) => {
        dispatcher(new EditTagCategoryCommand({form: data}));
        closeAndReset();
    }, [closeAndReset, dispatcher]);

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={closeAndReset}
            onSave={!isConfirmDeleteOpen ? handleSubmit(onSubmit) : undefined}
            onDelete={(!isLoc && category) ? handleDelete : undefined}
            title={category ? "Edit category" : "Create new category"}>
            {isConfirmDeleteOpen ? (
                <Typography variant="h6" style={{textAlign: "center", paddingBottom: theme.spacing(2)}}>
                    Do you want to delete this category?
                </Typography>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} style={{minWidth: "25rem"}}>
                    <Stack spacing={2}>
                        <GMTextField
                            select
                            size="small"
                            label="Color"
                            value={watch('color')}
                            {...register('color', {required: "Color is required"})}
                            error={!!errors.color}
                            helperText={errors.color?.message}
                        >
                            {colorsItems}
                        </GMTextField>
                        <GMTextField
                            size="small"
                            label="Name"
                            variant="outlined"
                            disabled={Boolean(category)}
                            {...register('name', {required: "Name is required"})}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <FormControlLabel
                            control={<Checkbox {...register('multiple')} defaultChecked={Boolean(categoryData?.multiple)} />}
                            label="Multiselection allowed"
                            labelPlacement="end"
                            disabled={isLoc}
                        />
                    </Stack>
                </form>
            )}
        </ModalFullScreenMobile>
    );
}
