//import { createApiForSpec } from "./api-factory";
import { ObjectApiRegistryImpl } from './api-registry-impl';
//import * as apiUtils from './api';

// const _OBJECT_API: apiUtils.NamedApiSpec = {
//     id: apiUtils.OBJECT_API,
//     spec: {
//         methods: [{ id: '_init' }],
//     },
// };

const BootstrapApiRegistry = new ObjectApiRegistryImpl();
// const result = createApiForSpec(_OBJECT_API)
// if (result.status !== 'resolved') {
//     throw new Error(`Failed to create API ${_OBJECT_API.id}: ${result.status}`);
// }
// BootstrapApiRegistry.store(result.api);
export { BootstrapApiRegistry };
