/**
 * model to statically describe Objects and their APIs
 * it is based on current "device interfaces" format.
 * Based on this schema, EntityApi can be created.
 * The main (only?) difference is how outlets (children) are represented here -
 * because an API may define many children of the same type, it wouldn't be practical
 * to enumerate all of them, instead we use OutletEntry as a way to multiple them.
 */

// in the future we'll need versioning, so it may become a structure
// we also need naming convention for private APIs, e.g.
// @{uuid} would be a private api defined by controller {uuid}
export type ProtocolID = string;

// not sure which term will be understandable by the typical user. in LUA there's no 'void', just nil
export const PROPERTY_TYPE_VOID = 'null';
/*
 * allowed value types
 * TBD: do we need to distinguish between object(struct) and array? hope not
 */

export type PropertyValue = number | string | boolean | object | null;

// TODO 'object' should be rather a reference to json-schema, otherwise API description is incomplete
// we may need 'lua-object' or 'reference' type though to be used in PRIVATE methods - items with this type couldn't
// be a part of serializable API
// JSON-schema would also provide a normalized way to describe constraints of parameters.
export const PropertyValueTypes = [PROPERTY_TYPE_VOID, 'number', 'string', 'boolean', 'object', 'integer'] as const;

export type PropertyValueType = (typeof PropertyValueTypes)[number];

export function isApiRef(api: ProtocolPtr): api is ProtocolPtrRef {
    return api.type === 'ref';
}

export function noSuchApiError(api: ProtocolPtr): Error {
    return new Error(`no such API: ${isApiRef(api) ? api.ref : '<spec>'}`);
}

export type ProtocolPtrRef = { type: 'ref'; ref: ProtocolID };
export type ProtocolPtrSpec = { type: 'spec'; spec: ProtocolSpec };

export type ProtocolPtr = ProtocolPtrRef | ProtocolPtrSpec;

export type NamedApiSpec = {
    id: ProtocolID;
    spec: ProtocolSpec;
};

/**
 * specification of a protocol
 * this is how it is defined in repo, so many fields are optional,
 * after loading it is normalized to Protocol type
 */
export type ProtocolSpec = {
    meta?: ProtocolMeta;
    extending?: ProtocolID[];
    events?: Event[];
    methods?: Method[];
    features?: Feature[];
    outlets?: Outlet[];
};

export type ProtocolMeta = {
    name?: string;
    description?: string;
    icon?: string;
};

export type Outlet = {
    id: string;
    label?: string;
    // if outlet api is inlined here by specifying OrderSchema,
    // it will be treated as a child:
    // - API will be anonymous
    // - versioned along with parent
    // - with auto-filled outlet "parent" reference
    // outlet that inline schema must be readonly and singleton
    protocol: ProtocolPtrRef;
    // readonly outlet has references already substituted and immutable
    // hardware composite objects define readonly outlets for their children,
    // which are "built-in" and cannot be detached/replaced
    // TODO this is "implementation" feature, not API feature. Should we move it?
    readOnly?: boolean;
    description?: string;
    bidirectional?: number;
    maxItems?: number;
};

export type Event = {
    id: string; //=id
    label?: string; //=name
    primary?: boolean;
    description?: string; //make mandatory?
};

/**
 * in our API we define a "property" (a value of a specific type) in three places:
 * 1. Feature itself is a property
 * 2. Method's parameter is a property
 * 3. Method's result is a property
 *
 * All of them should extend this interface to make them compatible with json-schema
 * (atm we implement a subset of json schema features)
 */
type JsonSchemaType = {
    type: PropertyValueType;
    minimum?: number;
    maximum?: number;
    default?: PropertyValue;
    oneOf?: Array<{ const: PropertyValue; title: string }>;
    description?: string;
};

export type Method = {
    id: string;
    params?: MethodParam[]; // "params" is used by json-rpc

    // "result" is used by json-rpc
    result?: {
        type: PropertyValueType;
        description?: string;
    };

    // meta
    label?: string; // editable methods have immutable 'name' and editable 'label'

    /*
     * problem with flags:
     *
     * methods/features inherited from base protocol are used differently in different contexts.
     * e.g. _init script is not provided hence it is editable, but _tagsWithCategory is provided and fully immutable.
     * _init cannot be called directly, it is a system "hook", _tagsWithCategory on the other hand it is intended to be used, so should be available in blockly.
     * on runtime panel they both should be hidden, they do not provide 'runtime' functionality (same applies to features _id, _name, _tags)
     * but since they can be called in script, they must appear in "actions", "blockly", target tree etc...
     *
     *
     * _init method : hook: true, readOnly: false, sealed: true
     * _tagsWithCategory: hook: false, readOnly: true, sealed: true
     *
     * currently we do not allow to edit signature of inherited method, so sealed is not needed.
     *
     * we could try to categorize them:
     *
     * default:                                               // regular methods, depending on implementation
     * hooks:    (_init)                                      // script is editable, but cannot be called by user
     * provided: (_tagsWithCategory(), _name, _id, _tags)     // can be called by user, but readOnly (for methods, script is not editable)
     *
     * _init seems to be very special, but I can imagine whole new way of notifying controllers about system events, just by controller implementing a dedicated protocol,
     * so let's keep the idea of hooks for now. same thing could be achieved with outlets, but it wouldn't be as clean.
     *
     * for action view it makes sense to display only those api items that perform some function (so all "provided" methods above will be hidden. same applies to "readOnly" features)
     * runtime panel should probably have hardcoded filtering, just to remove features/methods that are not very useful there (but they do not harm either if displayed)
     *
     * is it possible to have hook that is also provided? does not make much sense for scripts, but it can be a
     *
     */
    systemHook?: boolean; // built-in method that CANNOT be invoked by user code. however, unless readOnly==true, associated script can be edited
    readOnly?: boolean; // script cannot be edited, but unless hook=true, it can be called. implementation of such method is provided.
    scene?: boolean;
    description?: string; //make mandatory?
};

export type MethodParam = {
    id: string; //=ids
    label?: string; //=name
    // method params do not have default values in Grenton 2.0
    unit?: string;
    optional?: boolean; // TODO rename to required, move one level up
} & JsonSchemaType;

export type Feature = {
    id: string; //=id
    label?: string; //=name
    unit?: string;
    hint?: string;
    //set?: boolean
    //get?: boolean
    defaultRequired?: boolean;
    readOnly?: boolean;
} & JsonSchemaType;

export type FeatureValueOption<T> = {
    name: string;
    value: T;
};

export type Identify =
    | {
          type: 'output';
          method: { name: string; params?: [] } | { name: string; params?: [] }[];
      }
    | {
          type: 'input';
          feature: string;
          child?: string;
      };

//export const BASE_PROTOCOL_ID:ProtocolID = '~/object/1.0.0';
