import { I18nLangCodeEnum, LOC_CATEGORY, ROLE_ADMIN } from '@grenton/gm-common';
import { hashPassword } from '../../security';
import { FirmwareProvider } from '../firmwareProvider';
import { newProjectId } from '../id';
import { LOC_COLOR } from './defaults';
import { ProjectHardwareImpl } from '../model/hardware';
import { ProjectI18nImpl } from '../model/i18n';
import { ProjectImpl, ProjectRevisionsImpl } from '../model/project';
import { ProjectSecurityImpl, ProjectUserImpl, TEMPORARY_DEFAULT_USER_ROLES } from '../model/security';
import { ProjectTagsImpl } from '../model/tags';

export type CreateProjectImpl = ReturnType<typeof createProjectImpl>;

export function createProjectImpl(firmwareProvider: FirmwareProvider) {
    return async function () {
        const latestFirmware = firmwareProvider();
        if (!latestFirmware) {
            throw new Error('no firmwares available');
        }

        return new ProjectImpl({
            uuid: newProjectId(),
            firmware: latestFirmware,
            revisions: new ProjectRevisionsImpl([ProjectRevisionsImpl.revision('init')]),
            label: '<new>',
            i18n: new ProjectI18nImpl([{ code: I18nLangCodeEnum.EN, isDefault: true }]),
            tags: ProjectTagsImpl.from([]),
            hardware: ProjectHardwareImpl.empty(),
            modules: {},
            objects: {},
            security: ProjectSecurityImpl.from({
                users: [
                    ProjectUserImpl.from({
                        name: 'admin',
                        pwd: await hashPassword('admin'),
                        disabled: false,
                        roles: TEMPORARY_DEFAULT_USER_ROLES,
                    }),
                ],
                roles: [ROLE_ADMIN],
            }),
        }).withTagCategory(LOC_CATEGORY, LOC_COLOR, false, []);
    };
}
