import { EditableList } from "../../editableList";
import { ObjectEventForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer, renderItemListLabel } from "../../common";

export function EventList({ baseProtocol, items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectEventForm>) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const baseItems = baseProtocol.flat.events
    const listItems : ApiListItem<ObjectEventForm>[] = 
    Object.entries(items)
    .filter(([_,form])=>!baseItems[form.spec.id])
    .map(([id,form]) => ({ 
        id, 
        selectable: form.editable, 
        removeable: form.editable, 
        name: renderItemListLabel(form.spec),
        form
    })).toSorted((a,b) => a.name.localeCompare(b.name))

    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>
        {children}
    </EditableList>

}