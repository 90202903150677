import { Block, Blocks, FieldLabel, MenuOption } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, createDelegate, emptyOption, FieldDropdownModel, STATEMENT_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';
import { Lists } from '@grenton/gm-common';

export namespace TagCategoryBlock {
    export const Type = 'g-tag-category';

    export const TAGS = 'tags';

    export const CATEGORY_FIELD = 'cat';

    const CATEGORY_EMPTY_OPTION = emptyOption('{tag category}');

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        categoryDropdownModel: FieldDropdownModel;
        tree: { [category: string]: string[] } = {};

        constructor(private block: Block) {
            block.setOutput(true, 'string');
            this.categoryDropdownModel = new FieldDropdownModel(block, CATEGORY_FIELD, [CATEGORY_EMPTY_OPTION]);

            block.appendDummyInput().appendField(new FieldLabel('')).appendField(new FieldDropdownEx(this.categoryDropdownModel.generator), CATEGORY_FIELD);

            block.setInputsInline(false);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const selectedCategory = this.block.getFieldValue(CATEGORY_FIELD);
            this.tree = Lists.reduce(context.project.tags.categories, (cat) => [cat.name, cat.tags]);

            const categories: MenuOption[] = Object.keys(this.tree).map((cat) => [cat, cat]);
            categories.unshift(CATEGORY_EMPTY_OPTION);
            this.categoryDropdownModel.setOptions(categories, selectedCategory);
        }
    }
}

Blocks[TagCategoryBlock.Type] = createDelegate((block) => new TagCategoryBlock.Delegate(block));

luaGenerator.forBlock[TagCategoryBlock.Type] = (block) => {
    const code = `"${block.getFieldValue(TagCategoryBlock.CATEGORY_FIELD) || 'nil'}"`;
    return [code, Order.ATOMIC];
};
