import {
    BootstrapApiRegistry,
    Component,
    DeviceType,
    Lists,
    ObjectApi,
    ObjectApiRegistryImpl,
    ProtocolLoader,
    ProtocolLoaderError,
    RepoClient,
    SPEC_API_PREFIX,
    filterIt,
    isApiExtending,
    mapIt,
    schema,
    serializeFQKey,
} from '@grenton/gm-common';
import { createApiForSpec } from '@grenton/gm-common';

export class ProjectFirmwareImpl {
    static create(repoClient: RepoClient, version: string) {
        const firmware = repoClient.firmwares.value.find((fw) => fw.version === version);
        if (!firmware) {
            throw new Error(`unknown firmware version "${version}"`);
        }

        const dtVer = firmware.meta['device-types'];
        const deviceTypes: DeviceType[] = Object.entries(repoClient.deviceTypes.value.find((dt) => dt.version === dtVer)?.types || {})
            .map((entry) => ({
                id: entry[0],
                meta: entry[1].meta,
                protocols: entry[1].protocols,
            }))
            .toSorted((f1, f2) => {
                return (f1.meta?.name ?? f1.id).localeCompare(f2.meta?.name ?? f2.id);
            });

        const apis = repoClient.protocols.value.map((item) => ({
            id: serializeFQKey(item),
            spec: item.spec,
        }));

        const protocolRegistry = new ObjectApiRegistryImpl(BootstrapApiRegistry);
        const protocolLoader = new ProtocolLoader(
            (id) => protocolRegistry.resolveRef(id),
            (api) => protocolRegistry.store(api),
            firmware.baseProtocol,
        );

        const errors: ProtocolLoaderError[] = [];
        protocolLoader.errors.subscribe((error) => {
            errors.push(error);
        });
        protocolLoader.load(apis);
        if (errors.length) {
            throw new Error(`failed to load some protocols: ${errors.map((error) => `${error.id}:${error.reason}`).join('; ')}`);
        }

        const components = Lists.reduce(
            repoClient.components.value
                .filter((cmp) => (cmp.type !== 'module' && cmp.type !== 'system') || cmp.version === firmware.components[`${cmp.org}/${cmp.name}`])
                .map((spec) => ({ id: serializeFQKey(spec), spec })),
            (c) => [c.id, c],
        );
        return new ProjectFirmwareImpl(version, deviceTypes, protocolRegistry, components, firmware.baseProtocol);
    }

    private _deviceTypeById: { [id: string]: DeviceType };

    readonly baseProtocol: ObjectApi;

    constructor(
        public readonly version: string,
        private _functionalTypes: DeviceType[],
        private _apiRegistry: ObjectApiRegistryImpl,
        private _components: { [id: string]: Component },
        _baseProtocol: string,
    ) {
        this._deviceTypeById = Lists.reduce(_functionalTypes, (dt) => [dt.id, dt]);
        const baseProtocol = _apiRegistry.resolveRef(_baseProtocol);
        if (!baseProtocol) {
            throw new Error(`base protocol ${_baseProtocol} defined for firmware ${version} not found`);
        }
        this.baseProtocol = baseProtocol;
    }

    /**
     * each functional type defines a set of protocols it is applicable to.
     * by definition, it is also applicable to any of protocol that extends any of these protocols
     * @param protocols
     * @returns
     */
    getFunctionalTypes(protocols: ObjectApi | ObjectApi[]): DeviceType[] {
        const isExtendingAnyOf = Array.isArray(protocols)
            ? (parent: string) => protocols.find((protocol) => isApiExtending(protocol, parent))
            : (parent: string) => isApiExtending(protocols, parent);

        return this._functionalTypes.filter((type) => type.protocols.find(isExtendingAnyOf));
    }

    getFunctionalType(id: string): DeviceType | undefined {
        return this._deviceTypeById[id];
    }

    resolveAllowedApis(apiId: string): string[] {
        return [
            ...mapIt(
                filterIt(this._apiRegistry.all, (api: ObjectApi) => isApiExtending(api, apiId)),
                (api) => api.id,
            ),
        ];
    }

    /**
     * finds all functional types that are applicable to the target protocol.
     * each functional type defines a set of protocols it is applicable to, and it is also applicable to any of protocol that extends any of these protocols.
     * @param targetProtocol
     * @returns
     */
    resolveAllowedTypes(targetProtocol: string): DeviceType[] {
        return this._functionalTypes.filter((type) =>
            type.protocols.find((typeProtocol) => {
                // protocol may not be defined due to inconsistency in functionalTypes definition!
                const api = this._apiRegistry.resolveRef(typeProtocol);
                return api && isApiExtending(api, targetProtocol);
            }),
        );
    }

    get apis(): IterableIterator<ObjectApi> {
        return this._apiRegistry.all;
    }

    get components(): Component[] {
        return Object.values(this._components);
    }

    get deviceTypes(): DeviceType[] {
        return this._functionalTypes;
    }

    getComponent(id: string): Component | undefined {
        return this._components[id];
    }

    resolveObjectApi(objectId: string, api: schema.ProtocolPtr): ObjectApi {
        if (schema.isApiRef(api)) {
            return this.resolveApiRef(api);
        } else {
            const result = createApiForSpec({ id: `${SPEC_API_PREFIX}${objectId}`, spec: api.spec }, this.baseProtocol.id, (apiRef) =>
                this._apiRegistry.resolveRef(apiRef),
            );
            if (result.status === 'resolved') {
                return result.api;
            } else {
                throw new Error(`cannot resolve anonymous API due to ${result.status}`);
            }
        }
    }

    resolveApiRef(protocol: { ref: schema.ProtocolID }): ObjectApi {
        const api = this._apiRegistry.resolveRef(protocol.ref);
        if (!api) throw new Error(`API ${protocol.ref} not found`);
        return api;
    }
}
