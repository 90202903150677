import type {ReactElement} from "react";
import {Box, Button, Stack} from "@mui/material";
import {ObjectEditForm} from "@grenton/gm-logic";
import {ObjectEditPane} from "./objectEditPane";
import { ObjectPropertiesFormMeta } from "./objectEditPane/types";
import { GButtonBar } from "@grenton/design-system";
import { ValidatorResult } from "../../../ui/form-validation";
import { useState } from "react";
import { Popover, MenuItem } from "@mui/material";

type Props = {
    errors: ValidatorResult
    form: ObjectEditForm;
    meta: ObjectPropertiesFormMeta;
    onDelete: () => void;
    onDuplicate: () => void;
    onClose: () => void;
    onSave: () => void;
    onChange: (form: ObjectEditForm, modified:boolean) => void;
}

export function ObjectEditTab({form, meta, errors, onChange, onSave, onDelete,onDuplicate, onClose}: Props): ReactElement | null {
    if (!form || !meta) return null;

    const validatedForm = errors.valid
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete();
        handleMenuClose();
    };

    const handleDuplicate = () => {
        onDuplicate()
        handleMenuClose();
    };

    const open = Boolean(anchorEl);

    return (<Stack sx={{height:'100%', flexDirection:'column'}}>      
            <Box sx={{flexGrow:1, overflow:'hidden', display:'flex'}} className="props-content">
                <ObjectEditPane
                    meta={meta} 
                    form={form} 
                    errors={errors}
                    onChange={onChange}
                />
            </Box>
            <GButtonBar 
                start={
                    <>
                        <Button size="small" onClick={onClose}>Close</Button>
                        <Button size="small" onClick={handleMenuClick}>More</Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                            
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem disabled={meta.delete === 'none'} onClick={handleDelete}>Delete</MenuItem>
                            <MenuItem disabled={form.id.length > 1}  onClick={handleDuplicate}>Duplicate</MenuItem>
                        </Popover>
                    </>
                }
                end={<>
                    
                    <Button size="small" color="secondary" disabled={!validatedForm} onClick={onSave}>Save</Button>
                    <Button size="small" color="primary" disabled={!validatedForm} onClick={() => {onSave(); onClose();}}>Save and Close</Button>
                </>}
            />
            
            </Stack> 
    );
}
