import { Blocks, Block, FieldLabel } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import {
    BlockDelegate,
    BlockWithDelegate,
    createDelegate,
    emptyOption,
    FieldDropdownModel,
    getMenuOptions,
    NOT_SELECTED,
    STATEMENT_BLOCK_STYLE,
} from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';

export namespace FeatureGetBlock {
    export const Type = 'g-feature-get';

    export const ENTITY_FIELD = 'ENTITY';
    export const FEATURE_FIELD = 'FEATURE';
    const EMPTY_OPTION = emptyOption('{feature}');

    export class Delegate implements BlockDelegate {
        entityName?: string;
        entityType?: 'object' | 'outlet';
        featureName?: string;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, FEATURE_FIELD, [EMPTY_OPTION]);

            block.appendValueInput(ENTITY_FIELD);

            block.appendDummyInput().appendField(new FieldLabel('.')).appendField(new FieldDropdownEx(this.dropdownModel.generator), FEATURE_FIELD);

            this.updateBlockType(true);

            block.setInputsInline(true);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        updateBlockType(output: boolean) {
            this.block.setOutput(output, null);
            this.block.setPreviousStatement(!output, null);
            this.block.setNextStatement(!output, null);
        }

        onUpdate(context: BlockContext) {
            const entity = context.getInputType(ENTITY_FIELD);

            const features = entity?.api.flat.features || {};
            const options = getMenuOptions(Object.values(features));
            options.unshift(EMPTY_OPTION);

            let featureId = this.block.getFieldValue(FEATURE_FIELD);
            let selectedOption = options.find((o) => o[1] === featureId) || EMPTY_OPTION;

            this.dropdownModel.setOptions(options, selectedOption[1]);
            this.featureName = selectedOption[1] ? selectedOption[0]?.toString() : undefined;
        }

        connectToEntity(block: Block, featureId?: string) {
            const input = this.block.getInput(ENTITY_FIELD)!;
            if (block.outputConnection) {
                input.connection!.connect(block.outputConnection);
            }
            this.block.setFieldValue(featureId || '', FEATURE_FIELD);
        }
    }
}

Blocks[FeatureGetBlock.Type] = createDelegate((block) => new FeatureGetBlock.Delegate(block));

luaGenerator.forBlock[FeatureGetBlock.Type] = (block, generator) => {
    const _block = block as BlockWithDelegate<FeatureGetBlock.Delegate>;
    const entityRef = generator.valueToCode(block, FeatureGetBlock.ENTITY_FIELD, Order.ATOMIC) || NOT_SELECTED;
    const code = `${entityRef}.${_block.delegate.featureName || NOT_SELECTED}`;
    return [code, Order.ATOMIC];
};
