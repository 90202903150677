import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { textOverflowEllipsis } from "@grenton/design-system"
import { ObjectApi } from "@grenton/gm-common/src";

export type ApiItemListProps<ITEMFORM> = {
    baseProtocol:ObjectApi,
    items: Record<string,ITEMFORM>;
    editedItemId?: string,
    onEdit?: (itemId?: string) => void;
    onAdd?: () => void;
    onDelete?: (itemId: string) => void;
    children?: ReactNode;
}

export function renderItemListLabel(item:{id:string,label?:string}, emptyValue="<unnamed>") {
    return item.label === undefined ? item.id : item.label.trim().length === 0 ? emptyValue : item.label
}

export type ApiListItem<FORM> = {
    id:string
    form: FORM    
    selectable: boolean
    removeable: boolean
    name: string
}

export function ApiListItemRenderer<FORM extends {spec:{id:string,label?:string,description?:string}}>({ item, shrunk }: { item: ApiListItem<FORM>, shrunk: boolean }) {
    return (
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', height: 44, overflow: 'hidden' }}>
            <Typography variant="l" sx={{ flexGrow: 1, width: shrunk ? 'auto' : 250, minWidth: shrunk ? 0 : 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{renderItemListLabel(item.form.spec)}</Typography>
            {!shrunk && <Typography variant="s" sx={{ flexGrow: 1, ...textOverflowEllipsis(2) }}>{item.form.spec.description}</Typography>}
        </Stack>
    )
}