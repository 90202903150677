import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils';
import { createObjectsFromModuleComponent, createObjectsFromVirtualComponent } from '../builders';
import { ProjectImpl } from '../model';

export class ObjectDuplicateCommand implements Command {
    readonly type = 'ObjectDuplicateCommand';

    constructor(readonly objectId: string) {}
}

export class ObjectDuplicateCommandHandler implements CommandHandler<ObjectDuplicateCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'ObjectDuplicateCommand';
    }

    async execute(cmd: ObjectDuplicateCommand): Promise<void> {
        this.update((project) => {
            // we generally CANNOT duplicate single objects, but entire components!

            const object = project.requireObjectById(cmd.objectId);
            const component = project.requireModuleById(object.impl.componentRef.componentId);

            switch (component.type) {
                // for non-script objects we probably should focus on duplicating the entire component,
                // and ignore scripts. it is hard to copy scripts between anonymous controllers selectively
                case 'module':
                case 'system': {
                    return project.withComponentEntry(
                        createObjectsFromModuleComponent(
                            project.firmware,
                            component!.ref,
                            (label) => project.hasObjectWithLabel(label),
                            // object.tags.selected - these are tags for a single object, not all objects from this component,
                            // we cannot apply them to all, and applying them selectively also makes a lot of mess...
                            [],
                        ),
                    );
                }
                // as long as scriptable objects are packed one per component, we can try to copy scripts
                // this may require revisit when we allow multiple scriptable objects per component or custom user libraries
                case 'script': {
                    const entry = createObjectsFromVirtualComponent(
                        project.firmware,
                        component!.ref,
                        null,
                        (label) => project.hasObjectWithLabel(label),
                        true,
                        // object.tags.selected - these are tags for a single object, not all objects from this component,
                        // we cannot apply them to all, and applying them selectively also makes a lot of mess...
                        [],
                    );
                    // this is ok with an assumption 1 object / 1 component
                    let copy = entry.objects[0]!.object;
                    copy = copy.withInit(object.init);
                    copy = copy.withScripts(object.scripts);
                    // let's be consistent with module objects and ignore tags
                    //copy = copy.withTags(object.tags.selected);

                    const notInherited = (i: { inherited: boolean }) => !i.inherited;
                    // object has been created with original API spec from component, we want to copy over custom items
                    copy = copy.withApi(
                        copy.api
                            .withFeatures(Object.values(object.api.features).filter(notInherited))
                            .withMethods(Object.values(object.api.methods).filter(notInherited))
                            .withEvents(Object.values(object.api.events).filter(notInherited))
                            .withOutlets(Object.values(object.api.outlets).filter(notInherited)),
                    );

                    entry.objects[0]!.object = copy;
                    return project.withComponentEntry(entry);
                }
                default:
                    return project;
            }
        });
    }
}
