import { ReactNode, type ReactElement } from "react";
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import type { ProtocolTreeItem } from "./types";
import { Typography } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

function renderTreeItems(items: ProtocolTreeItem[], baseProtocolId:string, parent: string = 'root'): ReactNode[] {
    return items.map(item => parent !== 'root' && item.id === baseProtocolId ? null :
        <TreeItem itemId={`${parent}->${item.id}`} key={item.id} label={<Typography variant="m">{item.id}</Typography>}>
            {renderTreeItems(item.extending, baseProtocolId, item.id)}
        </TreeItem>)
}

export function ProtocolTree({ data, baseProtocolId }: { data: ProtocolTreeItem[], baseProtocolId:string }): ReactElement {

    return (<SimpleTreeView
        selectedItems={""}
        style={{ width: "100%" }}
        slots={
            {
                expandIcon: ArrowRight,
                collapseIcon: ArrowDropDown 
            }
        }
    >
        {renderTreeItems(data,baseProtocolId)}
    </SimpleTreeView>
    )
}
